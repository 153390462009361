header {
  height: 34.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-image: url('../../assets/img/museums_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .logo-container {
    .logo {
      width: 14rem;
      max-width: 100%;
      height: auto;
    }
  }

  .title-container {
    flex: auto;
    display: inline-flex;
    flex-direction: column;
    padding-top: 5rem;

    h1 {
      margin: 2rem 0;
      color: #174b85;
      font-size: 3rem;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 540px) {
  header {
    height: auto;
    padding: 1rem;
    background-size: contain;

    .logo-container,
    .title-container {
      text-align: center;
    }

    .title-container {
      padding-top: 0;
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}
