.section-header {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 2rem 1.5rem;

  .section-header-icon {
    width: auto;
    max-width: 4rem;
    height: auto;
    max-height: 4rem;
    margin-right: 2.5rem;
    color: red;
  }

  .section-header-title {
    color: #4b4b4b;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
  }
}
