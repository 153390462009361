section {
  position: relative;
  margin-bottom: 12rem;

  &:last-child {
    margin-bottom: 0;
  }

  .section-header {
    position: absolute;
    top: -8rem;
  }

  .section-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, 15rem);
    grid-gap: 1rem;
    padding: 2rem 4rem;
    padding-bottom: 0;
  }
}

@media (max-width: 40rem) {
  section {
    .section-content {
      justify-content: center;
    }
  }
}

@media (max-width: 540px) {
  section {
    margin-bottom: 4rem;

    .section-header {
      position: relative;
      top: 0;
      padding: 1rem 1.5rem;
    }
  }
}
