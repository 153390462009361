.section-card {
  width: 15rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #174b851a;
  border-radius: 0.75rem;
  text-decoration: none;
  cursor: pointer;

  img {
    max-height: 40%;
    max-width: 100%;
  }

  .title {
    margin-top: 2rem;
    font-size: 1.125rem;
    line-height: 1;
    color: #174b85;
    font-weight: 600;
  }
}
