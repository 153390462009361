footer {
  padding: 9rem 0;
  text-align: center;

  .logo {
    width: 21rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 540px) {
  footer {
    padding: 4.5rem 2.5rem;
  }
}
