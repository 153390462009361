* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  max-width: 71rem;
  margin: 0 auto;
  font-size: 1rem;
  font-family: "Titillium Web";
}

.warning {
  color: #f59100;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.25;
}
